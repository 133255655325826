import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import 'vuetify/dist/vuetify.min.css'
import './assets/styles/global.css'

axios.defaults.validateStatus = function (status) {
  return true
}

async function Init () {
  const configResponse = await axios.get('config.json')
  if (configResponse.status === 200) {
    window.AppConfig = configResponse.data
  }

  const avatarResponse = await axios.get(`${window.AppConfig.api_url}/auth/default/avatar`)
  if (avatarResponse.status === 200 && avatarResponse.data.success) {
    window.AvatarList = avatarResponse.data.data
  }

  Vue.config.productionTip = false
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

Init()

window.WebSocketObject = null

window.WebSocketConnect = function WebSocketConnect () {
  const id = sessionStorage.getItem('u_id')
  if (id === null || id === '') return

  window.WebSocketObject = new WebSocket(`${window.AppConfig.ws_url}${id}`)

  window.WebSocketObject.onclose = function (e) {
    console.log('WebSocket closed')
    const uid = sessionStorage.getItem('u_id')

    if (uid !== null && uid !== '') WebSocketConnect(uid)
  }

  window.WebSocketObject.onerror = function (e) {
    window.WebSocketObject.close()
  }
}

window.DateDiffYears = function (date1, date2) {
  let diff = (date1.getTime() - date2.getTime()) / 1000
  diff /= (60 * 60 * 24)
  return Math.abs(Math.ceil(diff / 365.25))
}

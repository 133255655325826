<script>
export default {
  name: 'copyright-footer'
}
</script>

<template>
  <v-footer app height="60px;">
    <v-container style="padding: 0;">
      <v-row dense no-gutters align="center" justify="center">
        <v-col cols="12" class="d-flex justify-center">
          <router-link to="/privacy">隱私權保護政策</router-link>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <router-link to="/service">服務條款</router-link>
        </v-col>
      </v-row>
      <v-row dense no-gutters align="center" justify="center">
        <v-col cols="12" class="d-flex justify-center">
          Copyright © 2024
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped>

</style>

<template>
  <App class="home-view">
    <template #app_bar>
      <div class="home-view">
        <v-container class="app-bar rounded-b-xl">
          <v-row no-gutters justify="center">
            <v-col cols="4"></v-col>
            <v-col cols="4" class="d-flex justify-center">
              <v-card
                flat
                color="white"
                height="40"
                width="120"
                style="margin: 0;"
                class="rounded-lg"
              >
                <v-img cover src="../assets/Logo-wide.png"></v-img>
              </v-card>
            </v-col>
            <v-col cols="4" class="d-flex justify-end">
              <v-btn
                text
                icon
                style="background-color: #dd8ea4; color: white; font-size: 18px; margin-right: 26px;"
                @click="$router.push('/login')"
              ><v-icon>mdi-login</v-icon>登入</v-btn>
            </v-col>
          </v-row>
          <v-row dense justify="center">
            <v-col cols="6" class="d-flex justify-center">
              <v-card
                flat
                color="white"
                class="rounded-lg"
                style="padding: 10px; width: 160px; height: 140px;"
                @click="$router.push('/register-man')"
              >
                <v-row justify="center" no-gutters>
                  <v-col cols="8" class="d-flex justify-center">
                    <v-img src="../assets/加入會員.png"></v-img>
                  </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                  <v-col class="d-flex justify-center">
                    <h3 style="color: #dd8ea4;">加入會員</h3>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="6" class="d-flex justify-center">
              <v-card
                flat
                color="white"
                class="rounded-lg"
                style="padding: 10px; width: 160px; height: 140px;"
                @click="$router.push('/register-woman')"
              >
                <v-row justify="center" no-gutters>
                  <v-col cols="8" class="d-flex justify-center">
                    <v-img src="../assets/成為女神.png"></v-img>
                  </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                  <v-col class="d-flex justify-center">
                    <h3 style="color: #dd8ea4;">成為女神</h3>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>

    <template #main>
      <v-container class="home-main">
        <v-row justify="start"><h2 style="margin: 10px;">為你推薦</h2></v-row>
        <v-row>
          <v-slide-group show-arrows>
            <v-slide-item v-for="(item, index) in recommended_list" :key="index">
              <v-card flat color="#f2e9eb" style="margin: 10px;">
                <v-row justify="center" no-gutters>
                  <v-col class="d-flex justify-center">
                    <v-avatar
                      size="64px"
                      @click="$router.push('/register-man')"
                      style="border: 2px solid #f2e9eb; outline: #dd8ea4 solid 2px;"
                    >
                      <v-img :src="item.url"></v-img>
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                  <v-col class="d-flex justify-center" style="font-size: 12px;">
                    {{item.nickname}}
                  </v-col>
                </v-row>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-row>
        <v-row justify="start"><h2 style="margin: 10px;">女神列表</h2></v-row>
        <v-row dense no-gutters>
          <v-col v-for="(item, index) in female_member_list" :key="index" cols="6" md="4">
            <v-card rounded="xl" style="margin: 5px; position: relative;">
              <v-img
                :src="item.image_url"
                :class="{ 'align-end': true, 'blur-image': !item.approved }"
                gradient="to bottom, rgba(0,0,0,0.01), rgba(0,0,0,0.5)"
                cover
                :height="photo_height"
                @click="$router.push('/register-man')"
              >
                <v-row v-if="item.approved" dense no-gutters style="padding: 0;">
                  <v-col cols="12" class="d-flex justify-end">
                    <v-chip
                      v-if="!item.cannot_take_call"
                      small
                      color="purple"
                      style="color: white; padding: 0 8px; margin: 4px;"
                    >
                      語音
                    </v-chip>
                    <v-chip
                      v-if="!item.cannot_take_video_call"
                      small
                      color="cyan"
                      style="color: white; padding: 0 8px; margin: 4px;"
                    >
                      視訊
                    </v-chip>
                  </v-col>
                  <v-col cols="12" class="d-flex align-end justify-start">
                    <h3
                      :style="{ color: 'white', 'font-weight': 'bolder', 'padding-top': `${(photo_height - 70)}px`, 'padding-left': '10px'}"
                    >
                      {{item.nickname + Age(item.birthday)}}
                    </h3>
                  </v-col>
                </v-row>
              </v-img>
              <v-card-title
                v-if="!item.approved"
                v-text="'審查中'"
                class="approving"
              ></v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-btn color="#dd8ea4" dark @click="RetrieveList">查看更多</v-btn>
      </v-container>
    </template>
    <template #footer>
      <Footer></Footer>
    </template>
  </App>
</template>

<script>
import axios from 'axios'
import App from '../components/app.vue'
import Footer from '../components/copyright-footer.vue'

export default {
  name: 'home-view',
  async mounted () {
    window.addEventListener('resize', this.OnResize)
    this.windowWidth = window.innerWidth
    this.genderType = window.sessionStorage.getItem('gender_type')
    if (this.genderType && this.genderType === 'MALE') {
      this.$router.push('/home-man')
      return
    }
    if (this.genderType && this.genderType === 'FEMALE') {
      this.$router.push('/home-woman')
    }

    await this.RetrievePinned()
    await this.RetrieveList()
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.OnResize)
  },
  computed: {
    photo_height () {
      let factor = 2
      if (this.windowWidth > 960) factor = 3
      // if (this.windowWidth > 992) factor = 6
      // if (this.windowWidth > 1200) factor = 12
      // console.log(`window.innerWidth = ${window.innerWidth}, factor = ${factor}`)
      return ((this.windowWidth > 800 ? 800 : this.windowWidth) - 30) / factor
    }
  },
  data: () => ({
    windowWidth: 600,
    genderType: null,
    recommended_list: [
      /*
      {
        id: 1,
        nickname: '女神一',
        url: '../../images/girl1.jpg'
      },
      {
        id: 2,
        nickname: '女神二',
        url: '../../images/girl2.jpg'
      },
      {
        id: 3,
        nickname: '女神三',
        url: '../../images/girl3.png'
      }
      */
    ],
    pageNum: 1,
    female_member_list: [
      /*
      {
        id: 1,
        nickname: '女神一',
        image_url: '../../images/girl1.jpg',
        is_online: true,
        approved: false,
        birthday: '2004-10-20'
      },
      {
        id: 2,
        nickname: '女神二',
        image_url: '../../images/girl2.jpg',
        is_online: true,
        approved: true,
        birthday: '2004-10-20'
      }
      */
    ]
  }),
  methods: {
    OnResize () {
      this.windowWidth = window.innerWidth
    },
    Age (birthday) {
      if (birthday && birthday !== '') {
        return ' ' + window.DateDiffYears(new Date(), new Date(birthday)).toString()
      } else {
        return ''
      }
    },
    async RetrievePinned () {
      const pinnedResponse = await axios.get(`${window.AppConfig.api_url}/index/pinned_picture`)
      if (pinnedResponse.status === 200 && pinnedResponse.data.success) {
        this.recommended_list = this.recommended_list.concat(pinnedResponse.data.data)
      }
    },
    async RetrieveList () {
      const listResponse = await axios.get(`${window.AppConfig.api_url}/index/list?page=${this.pageNum}&limit=8&order=dsc&sort=femaleRegister.clickRate`)
      if (listResponse.status === 200 && listResponse.data.success) {
        if (listResponse.data.data.records.length > 0) this.pageNum++
        listResponse.data.data.records.forEach(item => {
          let imageUrl = ''
          for (let i = 0; i < item.img_responses.length; i++) {
            const img = item.img_responses[i]
            if (img.status === 'APPROVED') {
              imageUrl = img.image_url
              break
            }
          }

          this.female_member_list.push({
            id: item.id,
            nickname: item.nickname,
            birthday: item.birthday,
            is_online: item.is_online,
            image_url: imageUrl !== '' ? imageUrl : item.img_responses[0].image_url,
            approved: imageUrl !== ''
          })
        })
      }
    }
  },
  components: {
    App: App,
    Footer: Footer
  }
}
</script>
<style scoped>
.home-view {
  background-color: #f2e9eb;
}
.app-bar{
  background-color: #dd8ea4;
}
@media (min-width: 840px) {
  .app-bar {
    max-width: calc(840px - 30px);
    margin-left: calc((100vw - 840px) / 2);
  }
}
.home-main {
  min-height: 100%;
  max-width: 800px;
}
.blur-image {
  filter: blur(8px);
  transform: scale(0.95);
}
.approving {
  color:white;
  font-size: 32px;
  font-weight: 1000;
  -webkit-text-stroke: 1px black;
  top: 50%;
  left: 50%;
  min-width: 130px;
  justify-content: center;
  position: absolute;
  display: block;
  z-index: 999;
  transform: translate(-50%, -50%);
}
</style>
